/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.ModalVideo {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vh 5vw 18vh;
  // transform: scaleY(0);
  cursor: none;
  // cursor: url("/fonts/close-100.png"), auto;
  background: $black;
  opacity: 0;
  width: 100vw;
  height: 100vh;

  @include desktop-s {
    padding: 2vh 15vw;
  }

  &.four-by-three {
      padding: 5vh 5vw 18vh;

    @media (max-height: 570px) {
     padding: 1vh 25vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 28vw 2vh;
    }

    @include desktop-s {
      padding: 2vh 15vw;
    }

  }

  &.nine-by-sixteen {
      padding: 5vh 5vw 5vh;

    @media (max-height: 570px) {
     padding: 1vh 25vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 28vw 2vh;
    }

    @include desktop-s {
      padding: 2vh calc(50% - 20vh);
    }

  }

  &.square {
      padding: 5vh 5vw 18vh;

    @media (max-height: 570px) {
     padding: 1vh 35vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 37vw 2vh;
    }

    @include desktop-s {
      padding: 2vh 27vw;
    }

  }

  @media (max-height: 570px) {
     padding: 1vh 20vw 2vh;
  }

  @media (max-height: 400px) {
     padding: 1vh 22vw 2vh;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    cursor: auto;
  }

  .ModalVideo__container {
    opacity:0;
    //  background: pink;
    transform: scale(1.4);
  }

  &__logo {
    position: absolute;
    top:2px;
    left:5vw;
    margin-top: 12px;

    svg {
      height: 22px;
      width: auto;

      @include tablet {
        height: 32px;
      }
    }

    @include tablet {
      // top: 0;
      margin-top: 20px;
    }

    @include desktop-m {
      margin-top: 25px;
    }
  }

  // cursor options
  .cursorClose {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display:none;
    pointer-events: none;
    opacity: 0;

    @include tablet {
      display: block;
    }

    svg {
      opacity:1;
      fill: $white;
      height: 40px;
      width: auto;
    }
  }

  .close {
    position: absolute;
    top:2.5vh;
    right: 6.5vw;
    color: $white;
    fill: $white;
    width: 27px;
    height: auto;

    svg {
      fill: $white;
    }

    @include tablet-m {
      display: none;

       @media (max-height: 570px) {
         top:4.5vh;
         display: block;
      }
    }
  }
}
