/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.TheHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  margin-top: -2px;

  @include tablet {
    padding-top: 0.7vw;
    margin-top: 0;
  }

  .showBgHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: -1%;
  }

  &.hideHeader {
    z-index: 0;
    opacity: 0;
  }

  &__main {
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 5px;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      padding-top: 20px;
      padding-right: 5vw;
      padding-bottom: 12px;
      padding-left: 5vw;
    }

    @include desktop-m {
      padding-top: 25px;
      padding-right: 5vw;
      padding-bottom: 14px;
      padding-left: 5vw;
    }
  }

  &__logo {
    margin-top: 8px;
    svg {
      height: 22px;
      width: auto;

      @include tablet {
        height: 32px;
      }

      @include desktop-xl {
        height: 48px;
      }
    }

    @include tablet {
      margin-top: 2px;
    }
  }

  &__buttons {
    position: relative;
    top: -4px;
    display: flex;
    align-items: flex-end;

    &--link {
      display: none;

      @include tablet {
        display: inline-block;
        margin-right: 20px;

        .dot {
          color: $orange;
        }

        &:hover {
          .name {
            color: $orange;
          }
          .dot {
            color: $white;
          }
        }
      }

      @include desktop-xl {
        line-height: 1;
      }
    }
  }

  &__search {
    position: relative;
    padding: 7px 4px;

    @include tablet {
      padding: 5px;
    }

    @include desktop-xl {
      padding: 0;
    }

    svg {
      fill: $white;
      height: 25px;
      width: auto;

      @include tablet {
        height: 27px;
      }

      @include desktop-xl {
        height: 37px;
      }
    }

    .IconSearch {
      svg {
        position: relative;
        top: 2px;
        height: 25px;

        @include tablet {
          height: 27px;
        }

        @include desktop-xl {
          height: 37px;
          margin-right: 10px;
        }
      }
    }
  }

  &__burger {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 6.5px;
    margin-left: 10px;

    @include tablet {
      padding: 5px;
    }

    @include desktop-xl {
      padding: 0;
    }

    .burger {
      display: flex;
      height: 23px;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      @include tablet {
        height: 25px;
      }

      @include desktop-xl {
        height: 33px;
      }

      span {
        display: block;
        width: 100%;
        height: 3px;
      }
    }
  }

  .cross {
    position: absolute;
    top: 10px;
    opacity: 0;

    @include tablet {
      top: 7px;
    }

    @include desktop-xl {
      top: 0;
    }

    svg {
      height: 24px;
      width: auto;
      fill: $black;

      @include tablet {
        height: 26px;
      }

      @include desktop-xl {
        height: 38px;
      }
    }
  }

  .cross-search {
    position: relative;
    top: 4px;
    opacity: 1;

    svg {
      height: 24px;
      width: auto;
      fill: $black;

      @include tablet {
        height: 26px;
      }
    }
  }
}
