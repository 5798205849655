/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.ProjectYoutube {
  position: relative;
  display: block;
  flex-direction: column;
  flex-grow: 1;
   max-width: 100vw;
    overflow-x: hidden;
  opacity: 1;
  width: 100%;

  &__cover {
    // position: absolute;
    // left: 0;
    // z-index: 2;
    // width: 100%;
    // height: 100%;
    //background: rgba(red, 0.5);
  }

  iframe {
    width: 100vw;
    height: 56.25vw;
  }


  &.super {
    @include paddingSuper;

    iframe {
      @include tablet{
        width: 76vw;
        height: 42.75vw;
      }

      @include desktop-l{
        width: 72vw;
        height: 40.5vw;
      }
    }
  }

  &.big {
    @include paddingBig;

    iframe{
      @include tablet{
        width: 83vw;
        height: 46.68vw;
      }
    }
  }

  &.standard {
    @include paddingMedium;

    iframe {
      @include tablet{
        width: 90vw;
        height: 60.62vw;
      }
    }
  }

  &.small {
    @include paddingSmall;

    iframe {
      @include tablet{
        width: 96vw;
        height: 54vw;
      }
    }
  }

    &.bottom-text {

    @include paddingMobileBottom;

    @include tablet {
       @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }

  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }
}
