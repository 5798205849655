/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.threeColumns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  &.reverse-true {
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;
    }
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.big {
    @include paddingBig;
  }

  &.super {
    @include paddingSuper;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &__description {
    @include paddingMobile;
    @include paddingMobileBottom;

    padding-top: 5vw;

    @include tablet {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    h1,
    h2,
    h3 {
      padding-top: 7.7vw;
      padding-bottom: 2vw;
      font-size: 32px;
      font-variation-settings: "wght" 750, "wdth" 100, "opsz" 30;
      width: 100%;
      line-height: 1.1;

      strong {
        margin-left: -8px;
        color: $orange;
      }

      @include tablet {
        padding-bottom: 0.3vw;
        font-size: 48px;

        strong {
          margin-left: -10px;
          color: $orange;
        }
      }

      @include desktop-sm {
        font-size: 54px;
        strong {
          margin-left: -12px;
          color: $orange;
        }
      }

      @include desktop-l {
        font-size: 64px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }

      @include desktop-xl {
        font-size: 74px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }
    }

    em {
      strong {
        display: block;
        height: 0;
        margin-bottom: 3vw;
        overflow: hidden;

        @include tablet {
          margin-bottom: 2vw;
        }
      }
    }

    @include tablet {
      &.padding-left {
        padding-left: 5vw;
      }

      &.padding-right {
        padding-right: 5vw;
      }

      &.padding-bottom {
        padding-bottom: 5vw;
      }

      &.padding-top {
        padding-top: 5vw;
      }
    }
  }

  &__left,
  &__center,
  &__right {
    width: 100%;

    @include tablet {
      width: 32%;
      margin-bottom: 0;
    }

    &--image {
      width: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.stretch {
    align-items: stretch;

    .threeColumns__left--image,
    .threeColumns__center--image,
    .threeColumns__right--image {
      height: 100%;

      &--item {
        object-fit: cover;
      }
    }

    // .threeColumns__left--image--item,
    // .threeColumns__center--image--item,
    // .threeColumns__right--image--item {
    //   object-fit: cover;
    // }
  }

  &.middle-false {
    .threeColumns__left,
    .threeColumns__center,
    .threeColumns__right {
      display: flex;
      margin-bottom: 0;
      width: 100%;

      @include tablet {
        width: 33.3333%;
      }

      &.hide-mobile {
        display: none;

        @include tablet {
          display: flex;
        }
      }
    }
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  &.mobile-remove-true {
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }
}
