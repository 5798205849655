/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.HomePage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;

  @include headerMargin;


  &__featured {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 9/16;
    @include marginBottom;

    @include tablet {
      aspect-ratio: 16/9;
    }

    &--image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: auto;
    }

    &--client {
      position: relative;
      font-size: 26px;
      color: $white;
      text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.3);
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 2.3vw;
        text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.5);
      }

      @include desktop-l {
        font-size: 36px;
      }
    }

    &--content {
      position: relative;
      z-index: 2;
      text-align: center;
    }

    &--title {
      position: relative;
      padding: 0 5vw 10px;
      margin-bottom: 1vw;
      font-size: 46px;
      line-height: 1;
      color: $white;
      text-shadow: 0.2vw 0.2vw 0.7vw rgba($black, 0.4);
      letter-spacing: -1px;
      font-variation-settings: "wght" 600, "wdth" 100, "opsz" 30;

      @include tablet-m {
        padding: 0;
        font-size: 6vw;
        line-height: 1.2;
        text-shadow: 0.3vw 0.3vw 2vw rgba($black, 0.7);
        letter-spacing: -2px;
      }

      @include desktop-l {
        font-size: 100px;
      }
    }

    &--subtitle {
      position: relative;
      font-size: 26px;
      color: $white;
      text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.3);
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 2.3vw;
        text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.5);
      }

      @include desktop-l {
        font-size: 36px;
      }
    }


  }



  &__bard {
    width: 100%;

    &--container {
      width: 100%;
      max-width: 100vw;
    }
  }
}
