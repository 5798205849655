/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.SearchResult {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 85px;
  // flex-wrap: wrap;

  @include tablet-m {
    @include paddingPageTop;
  }


  &__title {
    font-size: 32px;
    line-height: 1.1;
    color: $grey-dark;
    letter-spacing: -0.5px;
    font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

    @include tablet-m {
      font-size: 48px;

      br {
        display: none;
      }
    }

    @include desktop-sm {
      font-size: 54px;
      line-height: 1.25;
      letter-spacing: -0.5px;
    }

    @include desktop-l {
      font-size: 62px;
      letter-spacing: -1.5px;
    }

    .orange {
      margin-left: 2px;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    }
  }

  &__container {
    @include paddingMedium;

    margin-bottom: 5vw;
  }

  &__input {
    margin-top: 1vw;
    margin-right: auto;
    width: 100%;
    @include marginBottom;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__item {
    width: 100%;
    flex-grow: 0;
    @include marginBottomSmall;

    @include tablet {
      margin-right: 2.33%;
      width: 31.66%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &--image {
      width: 100%;
      margin-bottom: 5px;

      @include tablet {
        margin-bottom: 10px;
      }
    }
  }

  .no-results {
    &__title {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 48px;
      line-height: 1.1;
      letter-spacing: -1px;
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 70px;
      }

      @include desktop-sm {
        font-size: 74px;
        line-height: 1.25;
        letter-spacing: -1.5px;
      }

      @include desktop-l {
        font-size: 84px;
        letter-spacing: -2px;
      }
    }

    &__subtitle {
      br {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }
  }
}
