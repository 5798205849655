/**
 * First, we load all of this project's CSS dependencies.
 */

@import "~vue-agile/dist/VueAgile.css";

/**
 * Next, we load the project's variables and fonts.
 */
@import "@sass/_fonts.scss";
@import "@sass/_variables.scss";

/**
 * Then, we write the project's animations.
 */

@import "@sass/_vue-animations.scss";

/**
 * Finally, we write the project's global styles.
 */

@import "@sass/_reset.scss";

@import "@sass/_global.scss";
