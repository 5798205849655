/**
 * Fonts.
 *
 * See:
 *  - [Web Font Optimization](https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization)
 *  - [Controlling Font Performance with font-display](https://developers.google.com/web/updates/2016/02/font-display)
 */

@font-face {
  font-family: "HelveticaNowVar";
  src: url("/fonts/HelveticaNowVar/font.woff2") format("woff2"),
    url("/fonts/HelveticaNowVar/font.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNowVarItalic";
  src: url("/fonts/HelveticaNowVarItalic/font.woff2") format("woff2"),
    url("/fonts/HelveticaNowVarItalic/font.woff") format("woff");
}
