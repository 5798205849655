/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.HomeTwoColumsStretch {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  @include tablet {
    @include paddingMedium;

    flex-wrap: wrap;
  }

  .indexProject__image-container {
    margin-bottom: 0;
    height: 100%;
  }

  .item01 {
    order: 1;
    margin-bottom: 12px;

    @include tablet {
      order: initial;
      padding: 0 1vw 0 0;
    }

    img {
      object-fit: cover;
    }
  }

  .item02 {
    order: 3;
    margin-bottom: 12px;

    @include tablet {
      order: initial;
      padding: 0 0 0 1vw;
    }

    img {
      object-fit: cover;
    }
  }


  .item03 {
    order: 2;

    @include tablet {
      order: initial;
      padding: 0 1vw 0 0;
    }
  }

  .item04 {
    order: 4;

    @include tablet {
      order: initial;
      padding: 0 0 0 1vw;
    }
  }
}
