/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.Contact {
  display: flex;
  flex-direction: column;
  // background: pink;
  width: 100%;
  @include headerMargin;

  .main-text-link {
    display: block;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  .hover-white {
    @include transition;

    margin-bottom: 80px;

    @include tablet-m {
      margin-bottom: 0;
    }

    &:hover {
      color: $white;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20vh;
    padding-bottom: 10vh;
    margin: auto 0;
    background: $black;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    // background: green;
    width: 100%;

    @include tablet {
      width: 70%;
      margin-top: 0;
    }

    @include desktop-s {
      width: 65%;
    }

    svg {
      max-width: 550px;
      width: 100%;
      height: auto;
      margin-bottom: 5vh;

      @include tablet {
        width: 80%;
        margin-bottom: initial;
      }

      @include desktop-xl {
        width: 80%;
        max-width: 700px;
        margin-bottom: initial;
      }
    }

    &--newsletter {
      max-width: 700px;
      width: 100%;

      @include tablet {
        width: 80%;
      }
    }
  }

  &__locations {
    width: 100%;
    // background: red;

    @include tablet {
      width: 65%;
    }

    @include desktop-s {
      width: 35%;
    }

    * {
      line-height: 1.6;
    }

    &--item {
      margin-bottom: 30px;

      @include desktop-l {
        margin-bottom: 70px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .job-title-svg {
    svg {
      width: 90%;
      height: auto;
      margin-bottom: 10px;

      @include tablet {
        width: 100%;
      }
    }
  }

  &__jobs {
    @include paddingMobile;
    @include paddingPageTop;
    @include paddingBottom;

    @include tablet {
      @include paddingBig;
    }

    &--lang {
      margin-bottom: 3vh;
      width: 100%;
    }
  }
}

.job {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background: pink;
  width: 100%;
  @include marginBottom;

  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__left {
    width: 100%;
    margin-bottom: 15px;

    @include tablet {
      width: 35%;
    }

    .regular-text {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 23px;
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .regular-text {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 23px;
      }
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
  }

  &__right {
    width: 100%;

    @include tablet {
      width: 62%;
    }

    &--container {
      display: flex;
      flex-direction: column;
    }

    h2,
    h3 {
      margin-bottom: 15px;
      font-size: 21px;
      line-height: 1.4;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      strong {
        font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;
      }

      @include tablet {
        font-size: 23px;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
        font-size: 39px;
      }
    }

    p,
    li,
    a {
      margin-bottom: 20px;
      font-size: 17px;
      line-height: 1.4;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

       strong {
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      }

      @include tablet {
        font-size: 20px;
      }

      @include desktop-sm {
        font-size: 22px;
      }

      @include desktop-l {
        font-size: 26px;
      }

      @include desktop-xl {
        font-size: 29px;
      }
    }

    ul {
      margin-bottom: 20px;
      list-style: none;


      ul {
        padding-left: 15px;
        margin-top: 15px;

      }
    }

    li {
      margin-bottom: 10px;
      p {
        display: inline-block;
      }
    }

    li::before {
      position: relative;
      top: 50%;
      display: inline-block;
      margin-right: 10px;
      content: "";
      background: $orange;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
    }

    a {
      color: $orange;
      @include transition;

      &:hover {
        color: $grey;
      }
    }

    .mail-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 15px;
    }

    a.main-text-bold {
      display: inline-block;
      margin-left: auto;
      text-align: right;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
    }


  }
}

.js-spanish {
  display: none;
  opacity: 0;
}
