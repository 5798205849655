/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.metadata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100vw;
  padding-top: 5vw;
  // @include paddingTop;
  padding-bottom: 7.7vw;
  overflow-x: hidden;

  &.remove-true {
    padding-bottom: 0;
  }

  @include paddingMobile;

  @include tablet {
    @include paddingBig;

    padding-top: 0;
  }

  &__main {
    padding-bottom: 5vw;
    width: 100%;

    @include desktop-s {
      max-width: 68%;
      @include paddingBottomSmall;
    }
  }

  &__left {
    width: 100%;
    flex-grow: 0;

    @include tablet-m {
      width: 53%;
    }

    @include desktop-l {
      width: 51%;
    }
  }

  &__description {
    p {
      margin-bottom: 15px;
    }

    pre  * {
      font-family: $helvetica;
      font-size: 17px;
      line-height: 1.4;
      font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

      @include tablet {
        font-size: 20px;
      }

      @include desktop-sm {
        font-size: 22px;
      }

      @include desktop-l {
        font-size: 26px;
      }

      @include desktop-xl {
        font-size: 29px;
      }
    }

    a {
      color: $grey;

      &:hover {
        color: $orange;
      }
    }
  }

  &__right {
    width: 100%;
    flex-grow: 0;

    @include tablet-m {
      width: 35%;
    }

    @include desktop-l {
      width: 31%;
    }

    &--item {
      margin-bottom: 20px;
    }

    .download {

      svg {
        margin-left: 10px;
        opacity: 0;
        transform: translateY(2px);
        height: 16px;

        @include transition;


         @include tablet {
            height: 19px
          }

          @include desktop-sm {
            height: 20px;
          }

          @include desktop-l {
            height: 26px;
          }

          @include desktop-xl {
            height: 29px;
          }
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

    }

    .credit {
      strong {
        display: block;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      }

      p {
        margin-bottom: 20px;
      }

      a {
        color: $grey;
        &:hover {
          color: $orange;
        }
      }
    }

    .award {
      strong {
        display: block;
        margin-top: 15px;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

        &.firstAward {
           margin-top: 0;
        }
      }
    }

    .publication {
      strong {
        display: block;
        margin-top: 15px;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

        &.firstAward {
           margin-top: 0;
        }
      }
    }

  }

  .link {
    color: $grey;

    &:hover {
      color: $orange;
    }
  }
}
