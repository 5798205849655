/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.PrivacyPolicy {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  margin-top: 62px;

  @include headerMargin;

  @include tablet {
    margin-top: 0;
  }

  &__header {
    width: 100%;
    height: auto;
    margin-bottom: -10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    padding: 5vh 6vw;
    background: $grey-light;
    width: 100%;

    @include tablet {
      flex-direction: row;
      padding: 10vh 8vw;
    }

    .title {
      padding-right: 4vw;
      font-size: 40px;
      width: 100%;

      @include tablet {
        width: 40%;
      }
    }

    &--desc {
      font-size: 22px;
      line-height: 1.4;
      color: $black;
      width: 100%;

      @include tablet {
        width: 60%;
      }

      a {
        color: $orange;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    background: $white;
    width: 100%;

    @include tablet {
      flex-direction: row;
      padding: 10vh 8vw;
    }
  }

  &__identification {
    padding-right: 4vw;
    width: 100%;

    @include tablet {
      width: 40%;
    }

    &--title {
      font-size: 16px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    &--content {
      font-size: 16px;
      //line-height: 1.4;
      color: $grey;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }

      p {
        line-height: 2.4;
      }
    }
  }

  &__content {
    font-size: 16px;
    color: $grey;
    width: 100%;

    @include tablet {
      width: 60%;
    }

    @include desktop-s {
      font-size: 18px;
    }

    a {
      color: $grey-dark;
    }

    p {
      margin-bottom: 15px;
      line-height: 1.4;
    }

    h2 {
      //margin-bottom: 10px;
      font-size: 16px;
      line-height: 2.4;
      color: $orange;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    h3 {
      //margin-bottom: 10px;
      font-size: 16px;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    h4 {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 2.4;
      color: $black;
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    ul {
      margin-bottom: 15px;
      list-style: none;
    }

    li {
      margin-bottom: 15px;

      p {
        display: inline;
      }

      &::before {
        position: relative;
        display: inline;
        content: "- ";
      }
    }
  }
}
