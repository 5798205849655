/**
 * Globla Styles.
 */

/**
 * Index Project (Home/Work/Directors)
 */

.verticalSpaceMobile {
  width: 100%;
  height: 59px;
}

.verticalSpaceDesktop {
  width: 100%;
  height: 10vh;
  //background: blue;
}

.indexProject {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include marginBottom;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
  }

  &__image-container {
    position: relative;
    display: flex;
    flex-grow: 0;
    margin-bottom: 12px;
    overflow: hidden;
    width: 100%;

    @include tablet {
      margin-bottom: 12px;
    }
  }

  &__background-video {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-grow: 0;
    width: 100%;
    height: 100%;

    iframe {
      @include tablet-m {
        transform: scale(1);
        @include transition;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;

    @include desktop-s {
      transform: scale(1);
      transform-origin: center;
      @include transition;
    }

    &:hover {
      @include desktop-s {
        transform: scale(1.03);
      }
    }
  }

  &__text {
    @include paddingMobile;

    @include tablet {
      padding: 0;
    }
  }

  &__client {
    font-size: 18px;
    color: $grey;
    font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

    @include tablet {
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 22px;
    }

    @include desktop-l {
      font-size: 28px;
    }

    @include desktop-xl {
      font-size: 30px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 1.1;
    color: $grey-dark;
    letter-spacing: -0.5px;
    font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 30;

    @include desktop-sm {
      font-size: 42px;
      line-height: 1.25;
      letter-spacing: -1px;
    }

    @include desktop-l {
      font-size: 46px;
    }

    @include desktop-xl {
      font-size: 46px;
      letter-spacing: -1.5px;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 1.4;
    color: $orange;
    font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

    span {
      display: inline-block;
      margin-right: 8px;
    }

    @include tablet {
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 22px;
    }

    @include desktop-l {
      font-size: 28px;
    }

    @include desktop-xl {
      font-size: 30px;
    }
  }

  &__url {
    display: block;
    font-size: 18px;
    color: $grey;
    font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;
    @include paddingTopSmall;

    @include tablet {
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 22px;
    }

    @include desktop-l {
      font-size: 28px;
    }

    @include desktop-xl {
      font-size: 30px;
    }

    &:hover {
      color: $orange;
    }
  }
}

.Credits a {
  width: fit-content;
  color: $grey;

  &:hover {
    color: $orange;
  }
}

.credits-mark {
  h2,
  h3,
  h4 {
    font-size: 17px;
    line-height: 1.4;
    font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 30;

    @include tablet {
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 22px;
    }

    @include desktop-l {
      font-size: 26px;
    }

    @include desktop-xl {
      font-size: 29px;
    }
  }
}

/**
 * Filters (News/Work/Directors)
 */

.filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @include paddingMobile;
  @include marginBottomSmall;

  @include tablet {
    flex-direction: row;
  }

  @include tablet-m {
    justify-content: center;
    @include paddingBig;

    margin-bottom: 35px;
  }

  @include desktop-l {
    margin-bottom: 50px;
  }

  &__item {
    margin-right: 15px;
    margin-bottom: 18px;
    font-size: 19px;
    color: $grey;
    font-variation-settings: 'wght' 350, 'wdth' 100, 'opsz' 30;

    @include tablet-m {
      margin: 0 15px;
      margin-bottom: 0;
      font-size: 20px;
    }

    @include desktop-s {
      margin: 0 18px;
      margin-bottom: 0;
      font-size: 22px;
    }

    @include desktop-l {
      margin: 0 20px;
      font-size: 24px;
    }

    &:hover,
    &.active {
      color: $orange;
    }
  }
}

.title-svg {
  svg {
    height: 38px;
    width: auto;
    margin-top: 5px;

    @include tablet {
      height: 52px;
      margin-top: 10px;
    }

    @include desktop-l {
      height: 66px;
    }
  }
}

/**
 * Global Text
 */

.title {
  font-size: 40px;
  line-height: 1.2;
  color: $grey-dark;
  font-variation-settings: 'wght' 800, 'wdth' 100, 'opsz' 12;

  &.white {
    color: $white;
  }

  .square {
    color: $orange;
  }

  @include tablet {
    font-size: 54px;
  }

  @include desktop-l {
    font-size: 68px;
  }
}

.project-title {
  font-size: 33px;
  line-height: 1;
  font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 30;
  width: 100%;

  @include tablet {
    font-size: 48px;
    line-height: 1.1;
  }

  @include desktop-sm {
    font-size: 54px;
  }

  @include desktop-l {
    font-size: 64px;
  }

  @include desktop-xl {
    font-size: 74px;
  }
}

.section-title {
  padding-top: 5vw;
  padding-bottom: 5vw;
  font-size: 32px;
  line-height: 1;
  font-variation-settings: 'wght' 750, 'wdth' 100, 'opsz' 30;
  width: 100%;

  @include paddingMobile;

  @include tablet {
    padding-top: 0;
    font-size: 48px;
    line-height: 1.3;
    @include paddingBig;
  }

  @include desktop-sm {
    font-size: 54px;
  }

  @include desktop-l {
    font-size: 64px;
  }

  @include desktop-xl {
    font-size: 74px;
  }
}

.medium-text {
  font-size: 21px;
  line-height: 1.4;
  font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 23px;
  }

  @include desktop-sm {
    font-size: 28px;
  }

  @include desktop-l {
    font-size: 36px;
  }

  @include desktop-xl {
    font-size: 39px;
  }
}

.medium-text-bold {
  font-size: 21px;
  line-height: 1.2;
  font-variation-settings: 'wght' 600, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 23px;
  }

  @include desktop-sm {
    font-size: 28px;
  }

  @include desktop-l {
    font-size: 36px;
  }

  @include desktop-xl {
    font-size: 39px;
  }
}

.medium-title {
  font-size: 26px;
  font-variation-settings: 'wght' 800, 'wdth' 100, 'opsz' 12;
  line-height: 1.2;
  letter-spacing: -1px;

  @include tablet {
    font-size: 34px;
    letter-spacing: -2px;
  }

  @include desktop-sm {
    font-size: 40px;
  }

  @include desktop-l {
    font-size: 47px;
  }

  @include desktop-xl {
    font-size: 50px;
  }
}

.light-text {
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 300, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }
}

.regular-text {
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 400, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }
}

.small-title {
  font-size: 28px;
  font-variation-settings: 'wght' 600, 'wdth' 100, 'opsz' 30;
  line-height: 1.1;

  @include desktop-sm {
    font-size: 36px;
  }

  @include desktop-l {
    font-size: 40px;
  }

  @include desktop-xl {
    font-size: 40px;
  }
}

.small-subtitle {
  font-size: 16px;
  font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-l {
    font-size: 23px;
  }

  @include desktop-xl {
    font-size: 27px;
  }
}

.main-text-bold {
  font-size: 17px;
  line-height: 1.1;
  font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 20px;
    line-height: 1.3;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }
}

.main-text {
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }
}

.main-text-italic {
  font-family: $helveticaItalic !important;
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 300, 'wdth' 100, 'opsz' 12;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }
}

.main-text-link {
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;
  @include transition;

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    height: 15px;
    width: auto;
  }

  @include tablet {
    font-size: 20px;

    svg {
      height: 18px;
      width: auto;
    }
  }

  @include desktop-sm {
    font-size: 22px;

    svg {
      height: 20px;
      width: auto;
    }
  }

  @include desktop-l {
    font-size: 26px;

    svg {
      height: 23px;
      width: auto;
    }
  }

  @include desktop-xl {
    font-size: 29px;

    svg {
      height: 26px;
      width: auto;
    }
  }

  &:hover {
    color: $orange;
  }
}

.regular-text-link {
  font-size: 17px;
  line-height: 1.4;
  font-variation-settings: 'wght' 400, 'wdth' 100, 'opsz' 30;
  @include transition;

  @include tablet {
    font-size: 20px;
  }

  @include desktop-sm {
    font-size: 22px;
  }

  @include desktop-l {
    font-size: 26px;
  }

  @include desktop-xl {
    font-size: 29px;
  }

  &:hover {
    color: $orange;
  }
}

.rrss-link {
  @include transition;

  &:hover {
    color: $orange;
  }
}

/**
 * Colors
 */

.orange {
  color: $orange;
}

.grey {
  color: $grey;
}

.grey-dark {
  color: $grey-dark;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.footer-white {
  display: block;
  width: 100%;
  height: 1vh;
  background: $black;
}

.outline {
  border: 1px solid $orange;
}

/**
 * layouts
 */

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.hidden {
  display: none;
}

.opacity0 {
  opacity: 0;
}

.hidden-mobile {
  display: none;
  @include tablet {
    display: initial;
  }
}

.pointer {
  cursor: pointer;
}

/**
 * mobile settings
 */

.hide-mobile {
  display: none;

  @include tablet {
    display: initial;
  }
}

.show-mobile {
  @include tablet {
    display: none !important;
  }
}

.add-vertical-margin {
  @media (max-width: 576px) {
    padding-bottom: 5vw !important;
  }
}

.remove-vertical-margin {
  @media (max-width: 576px) {
    padding-bottom: 0 !important;
  }
}

.add-horizontal-margin {
  @media (max-width: 576px) {
    padding-right: 6vw !important;
    padding-left: 6vw !important;
  }
}

/**
 * FORM
 */

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  background: none;
  border: 1px solid $white;
  width: 25px;
  height: 25px;

  &.active {
    background: $orange;
  }

  &__container {
    position: relative;
    margin-right: 8px;
  }
}

.main-check {
  opacity: 0;
  width: 25px;
  height: 25px;
}

.user-edit {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 50;
  display: block;
  padding: 10px 20px;
  color: $white;
  text-align: center;
  cursor: pointer;
  background: $orange;
  font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 30;

  @include tablet {
    top: 0;
  }
}

.mobile-padding {
  @include paddingMobile;

  @include tablet {
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * AGILE CAROUSEL
 */

.CarouselDots {
  &__container {
    position: absolute;
    left: 50%;
    z-index: 9;
    display: flex;
    transform: translate(-50%, -8vh);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
    width: 55px;
    height: 35px;
    // background: blue;

    @include tablet {
      width: 60px;
    }

    @include desktop-l {
      width: 65px;
    }
  }

  &__highlight {
    position: relative;
    display: block;
    background: $white;
    width: 100%;
    height: 3px;

    @include desktop-l {
      height: 5px;
    }

    &.outside {
      background: $grey;
    }

    &::after {
      position: relative;
      top: 0%;
      left: 0;
      display: block;
      content: '';
      background: transparent;
      width: 1%;
      height: 3px;
      //animation: slideActive 6s linear;

      @include desktop-l {
        height: 5px;
      }
    }

    &.active {
      background: $orange;
    }

    &.animated {
      &::after {
        background: $orange;
        width: 100%;
        animation: slideActive 6s linear;
      }
    }
  }

  &__project {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 9;
    display: flex;
    transform: translate(-50%, 9px);

    @include tablet {
      transform: translate(-50%, 2vh);
    }

    &.inside {
      transform: translate(-50%, -5vh);

      @include tablet {
        transform: translate(-50%, -8vh);
      }
    }

    .CarouselDots__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: blue;
    }

    .CarouselDots__highlight {
      position: relative;
      display: block;
      background: $white;
      width: 100%;
      height: 100%;

      // @include desktop-l {
      //   height: 5px;
      // }

      &.outside {
        background: $grey;
      }

      &::after {
        display: none;
      }

      &.active {
        background: $orange;
      }

      &.animated {
        &::after {
          position: relative;
          top: 0%;
          left: 0;
          display: block;
          content: '';
          background: $orange;
          width: 100%;
          height: 100%;
          // animation: slideActive 6s linear;

          // @include desktop-l {
          //   height: 5px;
          // }
        }
      }
    }
  }
}

@keyframes slideActive {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.agile__nav-button--next {
  display: none;

  @include tablet-m {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    // cursor: url('/fonts/next-100.png'), auto;
    //background: rgba(pink, 0.4);
    opacity: 0;
    width: 25vw;
    height: 100%;
  }
}

.agile__nav-button--prev {
  display: none;

  @include tablet-m {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    cursor: none;
    // cursor: url('/fonts/prev-100.png'), auto;
    //background: rgba(pink, 0.4);
    opacity: 0;
    width: 25vw;
    height: 100%;
  }
}

.projectSlider.black-true {
  .agile__nav-button--next {
    display: none;

    @include tablet-m {
      display: block;
      //cursor: url('/fonts/next-100-black.png'), auto;
    }
  }

  .agile__nav-button--prev {
    display: none;

    @include tablet-m {
      display: block;
      //cursor: url('/fonts/prev-100-black.png'), auto;
    }
  }
}

.sliderControls {
  width: 100%;
  height: 100%;
}

//scroll-bar
.scrollbar-track {
  background: rgba(222, 222, 222, 0.2) !important;
}

.scrollbar-thumb {
  background: rgba(lightgray, 0.7) !important;
}

img[data-src] {
  opacity: 0.1;
}
.animate-lazy-load {
  // background-color: #BBB !important;
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
