/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.SingleProject {
  width: 100vw;
  max-width: 100vw;
  // overflow-x: hidden;
  @include headerMargin;

  &__section {
    max-width: 100vw;
    margin: -2px 0;
    //overflow-x: hidden;
    //overflow-y: visible;
  }

  &__additional-space {
    width: 100%;
    height: 5vw;
  }

  .Credits {
    width: 100%;

    .project-section-title {
      padding-top: 13vw;
      padding-bottom: 3vw;

      @include tablet {
        padding-top: 7.7vw;
        padding-bottom: 1vw;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @include paddingMobile;

      @include tablet {
        @include paddingBig;
      }
    }

    &__item {
      flex-grow: 0;
      margin-right: 4%;
      margin-bottom: 15px;
      width: 46%;

      &.itemSub {
        width: 100%;
        margin-top: 4vw;

        @include tablet {
          margin-top: 2vw;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
      }

      @include tablet {
        width: 31.1%;
        margin-right: 1%;
        margin-bottom: 24px;
      }
    }
  }

  .Related {
    width: 100%;

    .project-section-title {
      padding-top: 13vw;
      padding-bottom: 5vw;

      @include tablet {
        padding-top: 7.7vw;
        padding-bottom: 2vw;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include paddingBig;
    }

    &__item {
      flex-grow: 0;
      width: 100%;
      @include marginBottomSmall;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &--image {
        width: 100%;
        margin-bottom: 10px;
      }

      @include tablet {
        margin-right: 2.33%;
        width: 31.66%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    a {
      color: $grey;

      @include transition;

      &:hover {
        color: $orange;
      }
    }
  }
}

// cursor options
.cursorPlay,
.cursorPrev,
.cursorNext {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
  pointer-events: none;
  opacity: 0;

  @include tablet {
    display: block;
  }

  svg {
    opacity: 1;
    fill: $white;
    height: 40px;
    width: auto;
  }

  &.black-true {
    svg {
      fill: $black;
    }
  }
}
