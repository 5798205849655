/**
 * Variables.
 */

/* Colors */

$black: #000;
$grey-dark: #000;
$grey: #6e6e6e;
$grey-light: #f8f8f8;
$white: #fff;
$orange: #ff7f40;

/* Fonts */
$helvetica: 'HelveticaNowVar', sans-serif;
$helveticaItalic: 'HelveticaNowVarItalic', sans-serif;

/**
 * Mixins.
 */

/* Media Queries */

@mixin mobile() {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin mobile-m() {
  @media (min-width: 450px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-m() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-s() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop-sm() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-m() {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin desktop-l() {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (min-width: 2300px) {
    @content;
  }
}

/* Paddings */

@mixin headerMargin {
  // margin-top:58px;
  // @include tablet {
  //   margin-top: 70px;
  // }

  // @include desktop-m{
  //   margin-top:75px;
  // }
}

@mixin paddingMobile {
  padding-right: 6vw;
  padding-left: 6vw;
}

@mixin paddingMobileBottom {
  padding-bottom: 5vw;
}

@mixin paddingSmall {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@mixin marginBottomSmall {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottomSmall {
  padding-bottom: 10vw;

  @include tablet-m {
    padding-bottom: 2vw;
  }
}

@mixin paddingMedium {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@mixin marginBottom {
  margin-bottom: 10vw;

  @include tablet-m {
    margin-bottom: 5vw;
  }
}

@mixin paddingBottom {
  padding-bottom: 10vw;
  @include tablet-m {
    padding-bottom: 5vw;
  }
}

@mixin paddingBig {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 8.5vw;
    padding-left: 8.5vw;
  }
}

@mixin paddingBottomBig {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 8.5vw;
  }
}

@mixin paddingSuper {
  padding-right: 0;
  padding-left: 0;

  @include tablet {
    padding-right: 12vw;
    padding-left: 12vw;
  }

  @include desktop-l {
    padding-right: 14vw;
    padding-left: 14vw;
  }
}

@mixin paddingBottomSuper {
  padding-bottom: 10vw;

  @include tablet {
    padding-bottom: 12vw;
  }

  @include desktop-l {
    padding-bottom: 14vw;
  }
}

@mixin paddingPageTop {
  padding-top: 10vh;

  @include tablet-m {
    padding-top: 12vh;
  }
}

@mixin paddingBottomSmallProject {
  padding-bottom: 2vw;
}

@mixin paddingTop {
  padding-top: 4vh;

  @include tablet-m {
    padding-top: 8vh;
  }
}

@mixin paddingTopSmall {
  padding-top: 2.3vh;

  @include tablet-m {
    padding-top: 4vh;
  }
}

@mixin paddingSectionBottom {
  padding-bottom: 6vh;

  @include tablet-m {
    padding-bottom: 12vh;
  }
}

/* Containers */

@mixin container {
  //
}

/* Texts */

@mixin title {
  //
}

@mixin text {
  //
}

@mixin transition {
  transition: 0.3s all ease;
}


.About {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  // background: pink;
  @include headerMargin;

  .section-title {
    padding-bottom: 3vw;
  }

  &__header {
    position: relative;
    display: flex;
    background: $black;
    width: 100vw;
    height: 100vh;

    @include tablet {
      height: initial;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      width: 177.7vh;
      height: 100vh;

      @include tablet {
        position: relative;
        width: 100vw;
        height: 56.25vw;
      }
    }

    &--content {
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;

      @include tablet {
        position: absolute;
        width: 100vw;
        height: 56.25vw;
      }

      svg {
        max-width: 900px;
        width: 85vw;

        @include tablet {
          width: 49vw;
        }
      }
    }

    &--title {
      padding-left: 5vw;
      font-size: 15vw;
      line-height: 1;
      color: $white;
      text-shadow: 0.3vw 0.3vw 2vw rgba($black, 0.7);
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;

      strong {
        margin-left: -1vw;
        color: $orange;

        @include desktop-l {
          margin-left: -18px;
        }
      }

      em {
        font-style: normal;
        color: $orange;
      }

      @include tablet {
        padding-left: 0;
        font-size: 5vw;
      }

      @include desktop-l {
        font-size: 90px;

        p {
          width: 110%;
        }
      }
    }
  }

  &__main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5vh;
    padding-bottom: 3vh;

    @include paddingMobile;


    @include tablet {
      @include paddingPageTop;
      @include paddingBig;
      @include paddingSectionBottom;
    }

    &--title {
      width: 100%;
      margin-bottom: 5vw;

      @include tablet {
        width: 35%;
      }

      @include desktop-sm {
        width: 50%;
      }
    }

    &--content {
      width: 100%;

      @include tablet {
        width: 65%;
      }

      @include desktop-sm {
        width: 50%;
      }

      p {
        margin-bottom: 25px;
      }
    }

    .small-title {
      margin-bottom: 3vh;
    }
  }

  &__clients {
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    background: $white;
    width: 100%;
    @include paddingSectionBottom;

    @include tablet {
      @include paddingPageTop;
    }

    &--content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      @include paddingMobile;

      @include tablet {
        @include paddingBig;

        justify-content: flex-start;
      }
    }

    &--item {
      margin-bottom: 15px;
      font-size: 16.5px;
      line-height: 1.4;
      color: $black;
      width: 48.5%;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
      @include transition;

      @include tablet {
        margin-bottom: 20px;
        font-size: 23px;
        width: 33.33%;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
        font-size: 39px;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  &__partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 8vh;
    background: $black;
    @include paddingMobile;
    @include paddingSectionBottom;

    @include tablet {
      @include paddingPageTop;
      @include paddingBig;
    }

    &--item {
      width: 100%;

      &:first-child {
        margin-bottom: 5vh;

        @include tablet {
          margin-bottom: 0;
        }
      }

      @include tablet {
        width: 46%;
      }

      @include desktop-m {
        width: 43%;
      }
    }

    &--title {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 30px;
      }

      &.partner0 {
        strong {
          color: #e50914;
        }
      }

      &.partner1 {
        strong {
          color: #01d965;
        }
      }
    }
  }
}
