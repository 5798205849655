/**
 * Reset CSS.
 */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  // font-family: $helvetica;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: $helvetica;
  -webkit-text-size-adjust: none;

  &.horizontal {
    // position: fixed;
    //   max-height: 100%;
    //   overflow: hidden;
    background: $black;
    //   height: 100vh;
    //   width: 100vw;
  }
}

html {
  -webkit-text-size-adjust: none;
  font-family: $helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: $black;
  text-decoration: none;
  background: $white;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
}
